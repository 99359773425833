import { differenceInDays, isPast } from 'date-fns';
import { noop } from 'lodash';
import React, { createRef, useContext, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components/macro';
import { AssigneeStatusIndicatorGroup } from '../../shared/AssigneeStatusIndicator';
import { ExternalLink } from '../../shared/ExternalLink';
import { withModal, WithModalProps } from '../../shared/higher-order-components/withModal';
import MarkdownTextBox from '../../shared/MarkdownTextBox';
import { black, lightGrey, red, white } from '../../styling/colours';
import { smallFont, tinyFont } from '../../styling/fonts';
import {
  defaultBorderRadius,
  mediumNarrow,
  narrow,
  narrower,
  smallBorderRadius,
  tiny,
} from '../../styling/spacing';
import { regularTransitionDuration } from '../../styling/transitions';
import { assertNotNull } from '../../utils/assertNotNull';
import { formatDateStamp } from '../../utils/dateStamp';
import {
  Assignee,
  getAssigneesDoneById,
  UpdateGoalAssignmentDto,
} from '../goal-assignments/goalAssignment';
import { Goal, UpdateGoalDto } from '../goals/goal';
import { UpdateGoalModal } from '../goals/update-goal/UpdateGoalModal';
import { isValidUrlAndTicketRef } from '../projects/project';
import { ConfettiContext } from './confetti/ConfettiContext';
import { KanbanCardContextMenuButton } from './KanbanCardContextMenuButton';

const calculateBorderColour = (goal: Goal): string => {
  if (goal.stateCode === 'BACKLOG') {
    return white;
  }

  const lightness = 95 - 4 * Math.sqrt(differenceInDays(new Date(), goal.creationDate));
  return `hsl(35, 30%, ${lightness}%)`;
};

const getExternalTicketLink = (goal: Goal): string => {
  return goal.project.externalTicketUrlTemplate.replace(new RegExp(`({\w*})`), goal.goalGroupName);
};

type UpdateGoalModalParams = {
  goal: Goal;
  onSubmit: (updateGoalDto: UpdateGoalDto) => void;
  onDelete: (goalId: number) => void;
};

type UpdateGoalModalProps = WithModalProps<UpdateGoalModalParams, 'updateGoalModal'>;

type OwnProps = {
  goal: Goal;
  onUpdateAssigneeDone: (updateGoalAssignmentDto: UpdateGoalAssignmentDto) => void;
  onUpdateGoal: (updateGoalDto: UpdateGoalDto) => void;
  onDeleteGoal: (goalId: number) => void;
  onDuplicateGoal: () => void;
  onCompleteGoalAndDuplicate: () => void;
  isCardInLastColumn?: boolean;
  isViewOnly: boolean;
};

type KanbanCardProps = UpdateGoalModalProps & OwnProps;

export const KanbanCardComponent = ({
  goal,
  updateGoalModal,
  isViewOnly,
  onDuplicateGoal,
  onCompleteGoalAndDuplicate,
  onDeleteGoal,
  onUpdateGoal,
  onUpdateAssigneeDone,
  isCardInLastColumn,
}: KanbanCardProps) => {
  const confettiSpawnRef = createRef<HTMLDivElement>();
  const { getLastChangedGoalId, createConfetti } = useContext(ConfettiContext);

  useEffect(() => {
    if (goal.stateCode === 'COMPLETE' && confettiSpawnRef.current) {
      const confettiSpawnLocation = confettiSpawnRef.current.getBoundingClientRect();
      if (confettiSpawnLocation !== undefined && getLastChangedGoalId() === goal.goalId) {
        createConfetti(
          confettiSpawnLocation.x + confettiSpawnLocation.width / 2,
          confettiSpawnLocation.y + confettiSpawnLocation.height / 2,
          goal.assignees.map((a) => a.colour),
        );
      }
    }
  }, [goal.stateCode]);

  const onAssigneeClick = (mouseEvent: React.MouseEvent<HTMLElement>, assignee: Assignee) => {
    mouseEvent.stopPropagation();

    const updateGoalAssignmentDto: UpdateGoalAssignmentDto = {
      userId: assignee.userId,
      goalId: assertNotNull(assignee.goalId),
      assigneeDone: !assignee.assigneeDone,
    };

    onUpdateAssigneeDone(updateGoalAssignmentDto);
  };

  return (
    <Draggable draggableId={goal.goalId.toString()} index={goal.columnIndex}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          borderColour={calculateBorderColour(goal)}
          onClick={() =>
            updateGoalModal.open({
              goal,
              onSubmit: isViewOnly ? noop : onUpdateGoal,
              onDelete: isViewOnly ? noop : onDeleteGoal,
            })
          }
        >
          <ProjectNameAndGoalGroupContainer ref={confettiSpawnRef}>
            <ProjectName
              backgroundColour={goal.project.colour}
              textColour={goal.project.contrastColour}
            >
              {goal.project.name}
            </ProjectName>
            <GroupNameAndMenuContainer>
              {goal.goalGroupName && goal.goalGroupName.length && (
                <GoalGroup>
                  {goal.project.externalTicketUrlTemplate &&
                  isValidUrlAndTicketRef(
                    goal.project.externalTicketUrlTemplate,
                    goal.goalGroupName,
                    goal.project.jiraKeys,
                  ) ? (
                    <ExternalLink to={getExternalTicketLink(goal)}>
                      {goal.goalGroupName}
                    </ExternalLink>
                  ) : (
                    goal.goalGroupName
                  )}
                </GoalGroup>
              )}
              {!isViewOnly && (
                <KanbanCardContextMenuButton
                  onDelete={() => onDeleteGoal(goal.goalId)}
                  onDuplicate={onDuplicateGoal}
                  onCompleteAndDuplicate={() => onCompleteGoalAndDuplicate()}
                  isCardInLastColumn={!!isCardInLastColumn}
                />
              )}
            </GroupNameAndMenuContainer>
          </ProjectNameAndGoalGroupContainer>
          {goal.dueDate && (
            <DueDate overdue={isPast(goal.dueDate)}>Due: {formatDateStamp(goal.dueDate)}</DueDate>
          )}
          <Description>
            <MarkdownTextBox text={goal.description} />
          </Description>
          <AssigneeStatusIndicatorGroup
            assignees={goal.assignees}
            assigneesDoneById={getAssigneesDoneById(goal.assignees)}
            onAssigneeClick={onAssigneeClick}
            justify="end"
          />
        </Container>
      )}
    </Draggable>
  );
};

const withUpdateGoalModal = withModal<OwnProps, UpdateGoalModalParams, 'updateGoalModal'>(
  'updateGoalModal',
  ({ ownProps, params, closeModal, closeRequested, cancelCloseRequest }) => (
    <UpdateGoalModal
      goal={params.goal}
      onSubmit={params.onSubmit}
      onDelete={params.onDelete}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
      isViewOnly={ownProps.isViewOnly}
    />
  ),
  true,
  true,
);

export const KanbanCard = withUpdateGoalModal(KanbanCardComponent);

const containerBorderWidth = '1px';

const Container = styled.div<{ isDragging: boolean; borderColour: string }>`
  background-color: ${white};
  outline: none;
  border: solid ${containerBorderWidth} ${(props) => props.borderColour};
  border-radius: ${defaultBorderRadius};
  box-shadow: ${(props) => (props.isDragging ? '5px 5px 5px 0 rgba(0, 0, 0, 0.25)' : 'none')};

  padding: ${mediumNarrow};
  margin-bottom: ${mediumNarrow};
  position: relative;

  &:hover {
    border: solid ${containerBorderWidth} ${lightGrey};
  }

  transition: border ${regularTransitionDuration} ease;
  // Do not add a 'transition: 'all' ...' here because it screws up the draggable animation.
`;

const ProjectNameAndGoalGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${tinyFont};
  margin-bottom: ${narrow};
`;

const GroupNameAndMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectName = styled.div<{ backgroundColour: string; textColour: string }>`
  padding: ${tiny} ${narrower};
  border-radius: ${smallBorderRadius};
  color: ${(props) => props.textColour};
  background-color: ${(props) => props.backgroundColour};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const GoalGroup = styled.div`
  margin-left: ${narrower};
  word-break: break-all;
`;

const DueDate = styled.div<{ overdue: boolean }>`
  border-radius: ${smallBorderRadius};
  color: ${(props) => (props.overdue ? white : black)};
  display: inline;
  padding: ${(props) => (props.overdue ? `${tiny} ${narrower}` : 0)};
  background-color: ${(props) => (props.overdue ? red : white)};
  font-size: ${smallFont};
`;

const Description = styled.div`
  margin-top: ${narrow};
  margin-bottom: ${tiny};
  overflow-wrap: break-word;
`;
