import { find, noop } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components/macro';
import { ReactComponent as Info } from '../../images/icons/info-circle-solid.svg';
import { ReactComponent as Plus } from '../../images/icons/plus-solid.svg';
import { withModal, WithModalProps } from '../../shared/higher-order-components/withModal';
import { Icon } from '../../shared/Icon';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import {
  defaultBackgroundColour,
  lessTranslucentWhite,
  red,
  translucentTurquoise,
} from '../../styling/colours';
import { bold } from '../../styling/fonts';
import {
  defaultBorderRadius,
  mediumNarrow,
  mediumNarrowPixels,
  mediumPixels,
  narrow,
  narrower,
} from '../../styling/spacing';
import { regularTransitionDuration } from '../../styling/transitions';
import { DateStamp } from '../../utils/dateStamp';
import {
  Assignee,
  toAssignee,
  toUpdateGoalAssignmentDto,
  UpdateGoalAssignmentDto,
} from '../goal-assignments/goalAssignment';
import { CreateGoalModal } from '../goals/create-goal/CreateGoalModal';
import { CreateGoalDto, Goal, UpdateGoalDto } from '../goals/goal';
import { User } from '../users/user';
import { UsersContext } from '../users/UsersContextProvider';
import { Column, KanbanViewUrlParams } from './kanban';
import { KanbanCard } from './KanbanCard';

type CreateGoalModalParams = {
  projectId?: number;
  description?: string;
  dueDate?: DateStamp;
  notes?: string;
  goalGroupName?: string;
  goalStateCode: string;
  assignees?: Array<Assignee>;
  onSubmit: (createGoalDto: CreateGoalDto) => void;
};

type CreateGoalModalProps = WithModalProps<CreateGoalModalParams, 'createGoalModal'>;

type OwnProps = {
  urlParams: KanbanViewUrlParams;
  column: Column;
  goals: Array<Goal>;
  onUpdateAssigneeDone: (updateGoalAssignmentDto: UpdateGoalAssignmentDto) => void;
  onCreateGoal: (createGoalDto: CreateGoalDto) => void;
  onUpdateGoal: (updateGoalDto: UpdateGoalDto) => void;
  onDeleteGoal: (goalId: number) => void;
  onCompleteGoalAndDuplicate: (updateGoalDto: UpdateGoalDto, createGoalDto: CreateGoalDto) => void;
  loading: boolean;
  isLastColumn?: boolean;
  isViewOnly: boolean;
};

type KanbanColumnProps = CreateGoalModalProps & OwnProps;

const KanbanColumnComponent = ({
  urlParams,
  column,
  goals,
  onUpdateAssigneeDone,
  onCreateGoal,
  onUpdateGoal,
  onDeleteGoal,
  onCompleteGoalAndDuplicate,
  createGoalModal,
  loading,
  isLastColumn,
  isViewOnly,
}: KanbanColumnProps) => {
  const { activeUsers } = useContext(UsersContext);
  const viewUser: User | undefined = useMemo(
    () =>
      urlParams.userId
        ? find(activeUsers, (u) => u.userId === Number(urlParams.userId))
        : undefined,
    [urlParams, activeUsers],
  );

  const columnHeader = (
    <HeaderContainer
      isClickable={!column.isCompleteState && !isViewOnly}
      onClick={
        column.isCompleteState || isViewOnly
          ? noop
          : () =>
              createGoalModal.open({
                projectId: urlParams.projectId ? Number(urlParams.projectId) : undefined,
                goalStateCode: column.goalStateCode,
                assignees: viewUser ? [toAssignee(viewUser, null)] : undefined,
                onSubmit: onCreateGoal,
              })
      }
    >
      <Title>
        {column.title}
        {!column.isCompleteState && goals.length > 0 ? (
          <TicketCount>({goals.length})</TicketCount>
        ) : null}
      </Title>
      {column.isCompleteState ? (
        <Icon size="smaller" title="Only displays goals that were completed today">
          <Info />
        </Icon>
      ) : (
        !isViewOnly && (
          <Icon size="smaller" colour={red}>
            <Plus />
          </Icon>
        )
      )}
    </HeaderContainer>
  );

  return (
    <Container>
      <ConfettiCropContainer isLastColumn={isLastColumn!}>
        <CardsListContainer data-cy={`${column.title} column`}>
          <StyledLoadingOverlay loading={loading}>
            <Droppable droppableId={column.goalStateCode}>
              {(provided, snapshot) => (
                <CardList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {columnHeader}
                  {goals.map((goal) => (
                    <KanbanCard
                      key={goal.goalId}
                      goal={goal}
                      isCardInLastColumn={!!isLastColumn}
                      isViewOnly={isViewOnly}
                      onUpdateAssigneeDone={onUpdateAssigneeDone}
                      onUpdateGoal={onUpdateGoal}
                      onDeleteGoal={onDeleteGoal}
                      onDuplicateGoal={() =>
                        createGoalModal.open({
                          projectId: goal.project.projectId,
                          description: goal.description,
                          dueDate: goal.dueDate,
                          notes: goal.notes,
                          goalGroupName: goal.goalGroupName,
                          goalStateCode: goal.stateCode,
                          assignees: goal.assignees,
                          onSubmit: onCreateGoal,
                        })
                      }
                      onCompleteGoalAndDuplicate={() => {
                        createGoalModal.open({
                          projectId: goal.project.projectId,
                          description: goal.description,
                          notes: goal.notes,
                          goalGroupName: goal.goalGroupName,
                          goalStateCode: goal.stateCode,
                          assignees: goal.assignees,
                          onSubmit: (createGoalDto) => {
                            onCompleteGoalAndDuplicate(
                              {
                                goalId: goal.goalId,
                                description: goal.description,
                                goalStateCode: 'COMPLETE',
                                notes: goal.notes,
                                goalGroupName: goal.goalGroupName,
                                projectId: goal.project.projectId,
                                assignees: toUpdateGoalAssignmentDto(goal.assignees),
                              },
                              createGoalDto,
                            );
                          },
                        });
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </CardList>
              )}
            </Droppable>
          </StyledLoadingOverlay>
        </CardsListContainer>
      </ConfettiCropContainer>
    </Container>
  );
};

const withCreateGoalModal = withModal<OwnProps, CreateGoalModalParams, 'createGoalModal'>(
  'createGoalModal',
  ({ params, closeModal, closeRequested, cancelCloseRequest }) => (
    <CreateGoalModal
      projectId={params.projectId}
      description={params.description}
      dueDate={params.dueDate}
      notes={params.notes}
      goalGroupName={params.goalGroupName}
      goalStateCode={params.goalStateCode}
      assignees={params.assignees}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

export const KanbanColumn = withCreateGoalModal(KanbanColumnComponent);

const leftOffsetInPx = 300;
const rightOffsetInPx = 20;

const TicketCount = styled.span`
  font-size: 15px;
  color: #aaa;
  margin-left: 5px;
  position: relative;
  top: -1px;
`;

const Container = styled.div`
  margin: ${narrower};
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }

  width: 100%;
  min-width: 300px;
`;

const ConfettiCropContainer = styled.div<{ isLastColumn: boolean }>`
  width: calc(100% + ${leftOffsetInPx}px + ${rightOffsetInPx}px);
  min-width: calc(300px + ${leftOffsetInPx}px + ${rightOffsetInPx}px);
  height: 100%;

  position: relative;
  left: -${leftOffsetInPx}px;

  pointer-events: none;
  overflow: ${(props) => (props.isLastColumn ? 'hidden' : 'visible')};
`;

const CardsListContainer = styled.div`
  width: calc(100% - ${leftOffsetInPx}px - ${rightOffsetInPx}px);
  height: 100%;

  position: relative;
  left: ${leftOffsetInPx}px;

  pointer-events: auto;
  background-color: ${defaultBackgroundColour};
`;

const StyledLoadingOverlay = styled(LoadingOverlay)`
  height: 100%;
`;

const cardListPaddingPixels = mediumNarrowPixels;

const minCardListHeight = '340px';

const CardList = styled.div<{ isDraggingOver: boolean }>`
  height: 100%;
  min-height: ${minCardListHeight};

  padding: ${cardListPaddingPixels}px;
  padding-top: ${narrow};
  border-radius: ${defaultBorderRadius};

  transition: background-color ${regularTransitionDuration} ease;
  background-color: ${(props) =>
    props.isDraggingOver ? translucentTurquoise : lessTranslucentWhite};
`;

const HeaderContainer = styled.div<{ isClickable: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${mediumNarrow};
  margin-bottom: ${narrow};
  border-radius: ${defaultBorderRadius};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

  &:hover {
    background-color: ${(props) => (props.isClickable ? lessTranslucentWhite : undefined)};
  }

  transition: all ${regularTransitionDuration} ease;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: ${bold};
  white-space: nowrap;
`;
